/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <nav>
            <ul>
              <li>Questions? Call or Text: +1-801-600-1010</li>
              {/*
              <li>
                <a
                  href="/"
                  target="_blank"
                >
                  9Stone LLC
                </a>
              </li>

              <li>
                <a
                  href="/about_us"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/blog"
                >
                  Blog
                </a>
              </li>
              */}
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, 9Stone LLC.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
