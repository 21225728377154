import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
//  NavItem,
//  NavLink,
//  Nav,
//  TabContent,
//  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Pricing() {
//  const [pills, setPills] = React.useState("1");
//  const [plan, setPlan] = React.useState("1");

  const applyURL = "https://calendly.com/9stone/internet"
  return (
    <>
      <div className="cd-section" id="pricing">
        <div
          className="pricing-1 section-image"
          id="pricing-1"
          style={{
            backgroundImage: "url(" + require("assets/img/bg11.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Residential Internet Plans</h2>
                <h4 className="description">
                  All packages offer unlimited data at a highly competitive price.
                </h4>
                <div className="section-space"></div>
              </Col>
            </Row>
            <Row>
              {/*
              <Col md="3">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <h5 className="category">Basic</h5>
                    <CardTitle tag="h3">50Mbps</CardTitle>
                    <div className="icon icon-warning">
                      <i className="now-ui-icons tech_tv"></i>
                    </div>
                    
                    <CardTitle tag="h3">$45</CardTitle>                    
                    <ul>
                      <li>Up to 50Mbps Download<br />15-20Mbps Upload</li>
                      <li>Installation: $150</li>
                      <li>Our Small Family Plan; great for 2-4 users</li>
                      <li>Speed & Quality for any budget</li>
                      <li>5-10x faster than local DSL</li>
                      
                    </ul>
                    <Button
                      className="btn-neutral btn-round"
                      color="warning"
                      href={applyURL}
                      target="_blank"
                    >
                      Schedule Appointment
                    </Button>
                  </CardBody>
                </Card>
              </Col>
             */}
              <Col md="4">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <CardTitle tag="h3">Value Plan</CardTitle>
                    <div className="icon icon-info">
                      <i className="now-ui-icons tech_tv"></i>
                    </div>
                    <CardTitle tag="h3">$60</CardTitle>
                    <ul>
                      <li>Great Fit for Couples & Small Families</li>
                      <li>Installation: $170</li>
                      <li>Up to 100Mbps</li>
                      <li>Available in all our coverage areas</li>
                    </ul>
                    <Button
                      className="btn-neutral btn-round"
                      color="info"
                      href={applyURL}
                      target="_blank"
                   >
                      Schedule Appointment
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <CardTitle tag="h3">Pro Plan</CardTitle>
                    <div className="icon icon-success">
                      <i className="now-ui-icons tech_laptop"></i>
                    </div>
                    <CardTitle tag="h3">$80</CardTitle>
                    <ul>
                      <li>The Choice of Professionals & Active Families</li>
                      <li>Installation: $170</li>
                      <li>Up to 200Mbps</li>
                      <li>Working + School + Gaming + Streaming = Happy Household</li>
                      <li>Secure, low-latency service that meets work-from-home requirements</li>
                    </ul>
                    <Button
                      className="btn-neutral btn-round"
                      color="success"
                      href={applyURL}
                      target="_blank"
                    >
                      Schedule Appointment
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing card-plain">
                  <CardBody>
                   
                    <CardTitle tag="h3">Power Plan</CardTitle>
                    <div className="icon icon-danger">
                      <i className="now-ui-icons tech_headphones"></i>
                    </div>
                    <CardTitle tag="h3">$100</CardTitle>
                    
                    <ul>
                      <li>Our Fastest Most Bestest<br />for Power Users</li>
                      <li>Installation: $170</li>
                      <li>Up to 1000Mbps†</li>
                      <li>Excellent for gamers, creators, professionals, and others who deal with large files</li>
                      <li>Future-proof, low-latency, gigabit capable equipment</li>
                    </ul>
                    <Button
                      className="btn-neutral btn-round"
                      color="danger"
                      href={applyURL}
                      target="_blank"
                    >
                      Schedule Appointment
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h4 className="description">
                  <i>†UDP-based speed tests on gigabit Ethernet typically max out around 980Mbps. Real-world downloads from external sites range from 300-700Mbps. Actual results are dependent on network conditions between your equipment and the remote site.</i>
                </h4>
                <div className="section-space"></div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pricing-2" id="pricing-2">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Business Internet Options</h2>
               
              </Col>
            </Row>
            <Row>
              
              <Col md="4">
                <Card
                  className="card-pricing card-plain"
                  
                >
                  <CardBody>
                    <h5 className="category">Small Business</h5>
                    <CardTitle tag="h1">
                      <small>$</small>
                      70
                    </CardTitle>
                    <ul>
                      <li>
                        <b>70Mbps</b> Download Bandwidth
                      </li>
                      <li>
                        <b>Business Class</b> Support
                      </li>
                      <li>
                        <b>Unlimited</b> Data
                      </li>
                      <li>
                        Secure, Low-latency Service
                      </li>
                    </ul>
                    <Button
                      className="btn-round"
                      color="info"
                      href={applyURL}
                      target="_blank"
                   >
                      Schedule Appointment
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <h5 className="category">Business Standard</h5>
                    <CardTitle tag="h1">
                      <small>$</small>
                      150
                    </CardTitle>
                    <ul>
                      <li>
                        <b>150Mbps</b> Download Bandwidth
                      </li>
                      <li>
                        <b>Business Class</b> Support
                      </li>
                      <li>
                        Symmetrical Bandwidth Available
                      </li>
                      <li>
                        Reliable, Low-latency Network
                      </li>
                    </ul>
                    <Button
                      className="btn-round"
                      color="info"
                      href={applyURL}
                      target="_blank"
                    >
                      Schedule Appointment
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <h5 className="category">Business Dedicated</h5>
                    <CardTitle tag="h1">
                      <small>Starting at $200</small>
                    </CardTitle>
                    <ul>
                      <li>
                        <b>200Mbps</b> Bandwidth or more
                      </li>
                      <li>
                        <b>Priority</b> Support
                      </li>
                      <li>
                        Symmetrical Bandwidth &amp; Redundancy Available
                      </li>
                      <li>
                        Additional Custom Services Available
                      </li>
                    </ul>
                    <Button
                      className="btn-round"
                      color="info"
                      href="tel: +18016001010"
                      target="_blank"
                   >
                      Call for Details
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        
      </div>
    </>
  );
}

export default Pricing;
